import { DropdownStyled } from "./navbarStyle"
import janowopark from "../../images/slide1.png"
import { Link } from "gatsby-plugin-intl"
import locationIcon from "../../images/location.png"
import wiczlino1img from "../../images/wiczlino1.png"
import wiczlino2img from "../../images/wiczlino-2/z6g_mobile.jpg"
import janowoparktwo from "../../images/janowo-2/wiz-janowo-3_mobile.jpg"
import puckImage from "../../images/puck/z16-visual-mobile.jpg"
import ojp3Image from "../../images/z-17/Janowo_Park_III_01_V04_nav.jpg"
import React from "react"
import OutsideClickHandler from "react-outside-click-handler"
import { injectIntl } from "gatsby-plugin-intl"

const DropdownCompany = ({
  pose,
  handleHideDropdown,
  showDropdownArea,
  shortNavbar,
  companyDropdown,
  collapseMobileCompany,
  intl,
}) => {
  return (
    <DropdownStyled
      pose={pose}
      shortNavbar={shortNavbar}
      companyDropdown={companyDropdown}
      collapseMobileCompany={collapseMobileCompany}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          showDropdownArea && handleHideDropdown()
        }}
      >
        {/*{companyDropdown && (
          <div className={"container-dropdown dropdown-company"}>
            <div className="title">{intl.formatMessage({ id: "nav.dropdown.company.title" })}</div>
            <Link to={"/firma"}>
              <div className="single-anchor">{intl.formatMessage({ id: "nav.dropdown.company.about" })}</div>
            </Link>
            <Link to={"/kariera"}>
              <div className="single-anchor">
                {intl.formatMessage({ id: "nav.dropdown.company.career" })}
                <span>({jobOffersCount})</span>
              </div>
            </Link>
            <Link to={"/zakup-gruntow"}>
              <div className="single-anchor">{intl.formatMessage({ id: "nav.dropdown.company.landPurchase" })}</div>
            </Link>
          </div>
        )}
        {!companyDropdown && (*/}
        <div className={"container-dropdown"}>
          <div className="title">
            {intl.formatMessage({ id: "nav.dropdown.investments.title" })}
          </div>
          <div className="single-investment">
            <Link to={"/oferta/osiedle-janowo-park-3"} className="content">
              <div className="investment-image">
                <img src={ojp3Image} alt="" />
              </div>
              <div>
                <p>Osiedle Janowo Park III</p>
                <div className="location">
                  <img src={locationIcon} alt="" />
                  {intl.formatMessage({ id: "estate.city.rumia" })}
                </div>
              </div>
            </Link>
          </div>
          <div className="single-investment">
            <Link to={"/oferta/osiedle-pogodne-w-pucku"} className="content">
              <div className="investment-image">
                <img src={puckImage} alt="" />
              </div>
              <div>
                <p>Osiedle Pogodne w Pucku</p>
                <div className="location">
                  <img src={locationIcon} alt="" />
                  Puck nad morzem
                </div>
              </div>
            </Link>
          </div>

          <div className="single-investment ">
            <Link to={"/oferta/osiedle-gdynia-wiczlino-2"} className="content">
              <div className="investment-image">
                <img src={wiczlino2img} alt="" />
              </div>
              <div>
                <p className="d-flex align-items-center">
                  {intl.formatMessage({ id: "estate.name.wiczlinotwo" })}
                  <small className="ml-2 font-weight-light">
                    (Etap IIIA)
                  </small>
                </p>
                <div className="location">
                  <img src={locationIcon} alt="" />
                  {intl.formatMessage({ id: "estate.city.gdynia" })}
                </div>
              </div>
            </Link>
          </div>
          <div className="single-investment ">
            <Link to={"/oferta/osiedle-janowo-park-2"} className="content">
              <div className="investment-image">
                <img src={janowoparktwo} alt="OJP" />
              </div>
              <div>
                <p>{intl.formatMessage({ id: "estate.name.janowoParkTwo" })}</p>
                <div className="location">
                  <img src={locationIcon} alt="" />
                  {intl.formatMessage({ id: "estate.city.rumia" })} &mdash;
                  Ostatnie lokale usługowe
                </div>
              </div>
            </Link>
          </div>


          <div className="title" style={{ marginTop: "10px" }}>
            {intl.formatMessage({ id: "nav.dropdown.finished.title" })}
          </div>
          <div className="single-investment">
            <Link to={"/oferta/osiedle-janowo-park-2"} className="content">
              <div className="investment-image">
                <img src={janowoparktwo} alt="" />
              </div>
              <div>
                <p>{intl.formatMessage({ id: "estate.name.janowoParkTwo" })}</p>
                <div className="location">
                  <img src={locationIcon} alt="" />
                  {intl.formatMessage({ id: "estate.city.rumia" })}
                </div>
              </div>
            </Link>
          </div>
          <div className="single-investment ">
            <Link to={"/oferta/osiedle-gdynia-wiczlino-2/etap-1-2"} className="content">
              <div className="investment-image">
                <img src={wiczlino2img} alt="" />
              </div>
              <div>
                <p className="d-flex align-items-center">
                  {intl.formatMessage({ id: "estate.name.wiczlinotwo" })}
                  <small className="ml-2 font-weight-light">
                    (Etap I, II)
                  </small>
                </p>
                <div className="location">
                  <img src={locationIcon} alt="" />
                  {intl.formatMessage({ id: "estate.city.gdynia" })}
                </div>
              </div>
            </Link>
          </div>
          <div className="single-investment">
            <Link to={"/oferta/osiedle-janowo-park"} className="content">
              <div className="investment-image">
                <img src={janowopark} alt="OJP" />
              </div>
              <div>
                <p>{intl.formatMessage({ id: "estate.name.janowoPark" })}</p>
                <div className="location">
                  <img src={locationIcon} alt=" " />
                  {intl.formatMessage({ id: "estate.city.rumia" })}
                </div>
              </div>
            </Link>
          </div>
          <div className="single-investment">
            <Link to={"/oferta/osiedle-gdynia-wiczlino"} className="content">
              <div className="investment-image">
                <img src={wiczlino1img} alt="" />
              </div>
              <div>
                <p>{intl.formatMessage({ id: "estate.name.wiczlino" })}</p>
                <div className="location">
                  <img src={locationIcon} alt="" />
                  {intl.formatMessage({ id: "estate.city.gdynia" })}
                </div>
              </div>
            </Link>
          </div>
          {/*

          <div className="title" style={{ marginTop: "10px" }}>
            {intl.formatMessage({ id: "estate.status.preview" })}
          </div>
*/}
        </div>
        {/*)}*/}
      </OutsideClickHandler>
    </DropdownStyled>
  )
}
export default injectIntl(DropdownCompany)
