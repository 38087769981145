import React, { useState } from "react"
import Logo from "../shared/logo/logo"
import "bootstrap/dist/css/bootstrap.css"
import {
  NavbarStyled,
  HamburgerIconStyled,
  OfferMenuMobileStyled,
} from "./navbarStyle"
import { Link } from "gatsby-plugin-intl"
import { NavbarButtton } from "../shared/button/button"
import { Location } from "@reach/router"
import LanguageContext from "../../context/LanguageContext"
import DropdownArea from "./dropdown"
import DropdownServices from "./dropdownServices"
import { LanguageDropdown, LanguageDropdownButton } from "./languageDropdown"
import Language from "../../context/language"
import { injectIntl } from "gatsby-plugin-intl"
import { jobOffersCount } from "@/data/jobOffers"

const Navigation = ({ intl }) => {
  const [navState, toggleNav] = useState(false)
  const [showDropdownArea, toggleDropdownArea] = useState(false)
  const [showDropdownServices, toggleDropdownServices] = useState(false)
  const [showMobileDropdownServices, toggleMobileDropdownServices] = useState(
    false,
  )
  const [showOfferMobileMenu, toggleOfferMobileMenu] = useState(false)

  const handleShowDropdownServices = () => {
    toggleDropdownServices(true)
  }
  const handleHideDropdownServices = () => {
    toggleDropdownServices(false)
  }
  const handleViewDropdownArea = () => {
    toggleDropdownArea(!showDropdownArea)
  }
  const handleHideDropdown = () => {
    toggleDropdownArea(false)
  }
  const handleMobileDropdown = () => {
    toggleOfferMobileMenu(!showOfferMobileMenu)
    showMobileDropdownServices && toggleMobileDropdownServices(false)
  }
  const handleMobileDropdownServices = () => {
    toggleMobileDropdownServices(!showMobileDropdownServices)
    showOfferMobileMenu && toggleOfferMobileMenu(false)
  }
  let shortNavbar
  return (
    <Location>
      {({ location }) => {
        shortNavbar = false // location.pathname.split("/")[2] === ""
        const isHomePage = location.pathname.split("/")[2] === ""
        const currentLocation = location.pathname.split("/")[2]
        return (
          <>
            <Language />
            <LanguageContext.Consumer>
              {language => (
                <>
                  <NavbarStyled
                    shortNavbar={shortNavbar}
                    pose={navState ? "open" : "closed"}
                    collapseOffer={showOfferMobileMenu}
                    currentLocation={currentLocation}
                    collapseCompany={showDropdownServices}
                    collapseCompanyMobile={showMobileDropdownServices}
                  >
                    <div className="navigation">
                      <div className="logo">
                        <Link to="/">
                          <Logo theme={"dark"} />
                        </Link>
                      </div>
                      <HamburgerIconStyled
                        onClick={() => toggleNav(!navState)}
                        navOpen={navState}
                      >
                        <button
                          className={`hamburger ${navState && "close-button"}`}
                        >
                          <div className="line" />
                          <div className="line" />
                          <div className="line" />
                        </button>
                      </HamburgerIconStyled>
                    </div>
                    <div
                      className={`nav-content ${
                        isHomePage ? "nav-content--lite" : ""
                      } `}
                    >
                      <ul>
                        <li
                          className={`dropdown-link desktop ${currentLocation ===
                          "oferta" && "active"} ${showDropdownArea &&
                          "active"}`}
                          onClick={handleViewDropdownArea}
                        >
                          {intl.formatMessage({ id: "nav.investments" })}
                        </li>
                        <li
                          className={`dropdown-link mobile ${currentLocation ===
                          "oferta" && "active"} ${showOfferMobileMenu &&
                          "active"}`}
                          onClick={handleMobileDropdown}
                        >
                          {intl.formatMessage({ id: "nav.investments" })}
                        </li>
                        <OfferMenuMobile
                          pose={showOfferMobileMenu ? "openMenu" : "closedMenu"}
                        />


                        <Link
                          to="/firma"
                          className={`${
                            currentLocation === "firma" ? "active" : ""
                          }`}
                          onClick={() => toggleNav(false)}
                        >
                          {intl.formatMessage({ id: "nav.company" })}
                        </Link>
                        <Link
                          to="/kariera"
                          className={`${
                            currentLocation === "kariera" ? "active" : ""
                          }`}
                          onClick={() => toggleNav(false)}
                        >
                          {intl.formatMessage({
                            id: "nav.dropdown.company.career",
                          })}
                          &nbsp;
                          <small style={{ color: "#94C835" }}>
                            <sup>({jobOffersCount})</sup>
                          </small>
                        </Link>
                        <Link
                          to="/zakup-gruntow"
                          className={`${
                            currentLocation === "zakup-gruntow" ? "active" : ""
                          }`}
                          onClick={() => toggleNav(false)}
                        >
                          {intl.formatMessage({
                            id: "nav.dropdown.company.landPurchase",
                          })}
                        </Link>

                        {/*<div
                          onClick={handleShowDropdownCompany}
                          className={`dropdown-company desktop ${showDropdownCompany &&
                            "active"} ${currentLocation === "firma" &&
                            "active"} ${currentLocation === "kariera" &&
                            "active"} ${currentLocation === "zakup-gruntow" &&
                            "active"}`}
                        >
                          {intl.formatMessage({ id: "nav.company" })}
                        </div>*/}
                        {/*<div
                          className={`dropdown-company mobile ${currentLocation ===
                            "firma" && "active"} ${showMobileDropdownCompany &&
                            "active"}`}
                          onClick={handleMobileDropdownCompany}
                        >
                          {intl.formatMessage({ id: "nav.company" })}
                        </div>*/}
                        {/*<CompanyMenuMobile
                          pose={
                            showMobileDropdownCompany
                              ? "openMenu"
                              : "closedMenu"
                          }
                        />*/}
                        <Link
                          to="/kontakt"
                          className={`${
                            currentLocation === "kontakt" ? "active" : ""
                          }`}
                          onClick={() => toggleNav(false)}
                        >
                          {intl.formatMessage({ id: "nav.contact" })}
                        </Link>


                        <NavbarButtton
                          theme={"dark"}
                          className={"mobile-nav-button"}
                        />
                        <span className="mobile-button-everywhere" />
                        <span className={"tablet-nav-button"}>
                          <NavbarButtton theme={"dark"} />
                          <LanguageDropdownButton
                            handleOpen={language.handleOpenDropdown}
                          />
                        </span>
                        <span className={"d-none d-lg-flex desktop-nav-button"}>
                          {!shortNavbar && <NavbarButtton theme="dark" />}
                          {!shortNavbar && <LanguageDropdownButton />}
                        </span>
                      </ul>
                    </div>
                  </NavbarStyled>
                  <DropdownArea
                    shortNavbar={shortNavbar}
                    pose={showDropdownArea ? "show" : "hide"}
                    handleHideDropdown={handleHideDropdown}
                    showDropdownArea={showDropdownArea}
                  />
                  <DropdownServices
                    shortNavbar={shortNavbar}
                    pose={showDropdownServices ? "show" : "hide"}
                    handleHideDropdown={handleHideDropdownServices}
                    showDropdownArea={handleShowDropdownServices}
                  />
                  <LanguageDropdown />
                </>
              )}
            </LanguageContext.Consumer>
          </>
        )
      }}
    </Location>
  )
}

const OfferMenuMobile = ({ pose }) => (
  <OfferMenuMobileStyled pose={pose}>
    <ul>
      <Link to={"/oferta/osiedle-janowo-park-3"}>Osiedle Janowo Park III</Link>
      <Link to={"/oferta/osiedle-pogodne-w-pucku"}>Osiedle Pogodne</Link>

      <Link to={"/oferta/osiedle-gdynia-wiczlino-2"}>
        Osiedle Gdynia Wiczlino II
      </Link>
      <Link to={"/oferta/osiedle-janowo-park-2"}>Osiedle Janowo Park II</Link>
      <Link to={"/oferta/osiedle-janowo-park"}>Osiedle Janowo Park</Link>
      <Link to={"/oferta/osiedle-gdynia-wiczlino"}>
        Osiedle Gdynia Wiczlino
      </Link>
    </ul>
  </OfferMenuMobileStyled>
)
const ServiceMenuMobile = ({ pose }) => (
  <OfferMenuMobileStyled pose={pose}>
    <ul>
      <Link to={"/mieszkanie-pod-klucz/zmiany-aranzacyjne"}>
        Zmiany aranżacyjne
      </Link>
      <Link to={"/mieszkanie-pod-klucz/wykonczenie-pod-klucz"}>
        Wykończenia pod klucz
      </Link>
    </ul>
  </OfferMenuMobileStyled>
)
export default injectIntl(Navigation)
